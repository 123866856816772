import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import moment from "moment";
import { Container, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";

import * as W09F1501Actions from "../../../../redux/W0X/W09F1501/W09F1501_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { browserHistory } from "react-router";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import { MForm } from "../../../common/form-material";
import W09F1501EmployeeInfo from "./W09F1501EmployeeInfo";
import W09F1501OrgRec from "./W09F1501OrgRec";
import { ButtonIcon } from 'diginet-core-ui/components';
import CDN from "../../../CDN";

const styles = theme => {
  return {
    root: {
      minWidth: "200px",
      width: "20%",
      height: "35px",
      float: "left",
      paddingLeft: "-15px"
    },
    flexBox: {
      display: "flex"
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F9F9F9",
      marginLeft: "-15px",
      marginTop: "-15px",
      [theme.breakpoints.down("xs")]: {
        width: "40px",
        overflow: "hidden"
      }
    },
    tab: {
      backgroundColor: "#F9F9F9",
      height: "989px"
    },
    selected: {
      backgroundColor: "white",
      borderRight: "10px red bold"
    },
    wrapper: {
      flexDirection: "row",
      justifyContent: "start"
    },
    rootTab: {
      width: "240px",
      minHeight: "35px"
    },
    iconStyle: {
      marginRight: "22px",
      width: "16px",
      height: "16px"
    },
    bgIndicator: {
      backgroundColor: "white"
    },
    heading: {
      fontSize: theme.typography.pxToRem(23),
      fontWeight: 450
    },
    buttonUp: {
      position: "absolute",
      bottom: 12,
      right: 12
    },
    ExpansionPanelSummary: {
      borderBottom: "2px solid " + theme.palette.primary.main,
      padding: 0,
      maxWidth: 300,
      color: theme.palette.primary.main,
      "& .MuiExpansionPanelSummary-content": {
        margin: "12px 0"
      },
      "&.Mui-expanded": {
        minHeight: "inherit"
      }
    },
    noneBorder: {
      "&:before": {
        backgroundColor: "transparent"
      }
    },
    txtNote: {
      position: "absolute",
      bottom: -30,
      whiteSpace: "pre",
      fontStyle: "italic",
      color: "grey",
      fontSize: 14
    }
  };
};

class W09F1501 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoading: false,
      uploading: false,
      iPermission: 0,
      errors: {},
      expandedFirst: true,
      expandedSecond: true,
      avatarAttach: null,
      formData: {
        EmployeeName: "",
        Sex: "",
        Telephone: "",
        DateJoined: null,
        NumIDCard: "",
        NumIDCardDate: null,
        NumIDCardPlace: "",
        IDCard: "",
        IDCardDate: null,
        IDCardPlace: "",
        DutyID: "",
        Project: null,
        ContactAddress: "",
        Note: "",
        UserPictureURL: "",
        DirectManagerID: "",
        DepartmentID: ""
      },
      dataOldAttachments: []
    };
    this.attachments = [];
    this.deletedFile = [];
    this.upload = null;
  }

  loadPermission = async () => {
    await this.props.generalActions.getPermission("W09F1501", isPer => {
      this.setState({ iPermission: isPer });
    });
  };

  loadApi = (cboName, params = {}, ownAction = false, cbSetState) => {
    this.setState({ formLoading: true });
    this.props[ownAction ? "w09f1501Actions" : "generalActions"][cboName](params, (error, data = null) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (cboName === "loadForm") {
        this.setState({
          formData: {
            EmployeeName: _.get(data, "EmployeeName", ""),
            Sex: _.get(data, "Sex", ""),
            Telephone: _.get(data, "Telephone", ""),
            NumIDCard: _.get(data, "NumIDCard", ""),
            NumIDCardDate: _.get(data, "NumIDCardDate", null),
            NumIDCardPlace: _.get(data, "NumIDCardPlace", ""),
            IDCard: _.get(data, "IDCard", ""),
            IDCardDate: _.get(data, "IDCardDate", null),
            IDCardPlace: _.get(data, "IDCardPlace", ""),
            DutyID: _.get(data, "DutyID", ""),
            Project: { ProjectID: _.get(data, "ProjectID", ""), ProjectName: _.get(data, "ProjectName", "") },
            ContactAddress: _.get(data, "ContactAddress", ""),
            Note: _.get(data, "Note", ""),
            DirectManagerID: _.get(data, "DirectManagerID", ""),
            DepartmentID: _.get(data, "DepartmentID", ""),
            UserPictureURL: Config.getCDNPath() + _.get(data, "UserPictureURL", ""),
            DateJoined: moment.utc(_.get(data, "DateJoined", "")).format("YYYY-MM-DD")
          }
        });
      }
      if (cboName === "getAttachmentsByTransID" && !_.isEmpty(data)) {
        this.setState({
          dataOldAttachments: data
        });
      }
      this.setState({ formLoading: false });
    });
  };

  _getAttachments = () => {
    const listAttachments = Config.helpers.getFileInfomations(this.attachments);
    const { dataOldAttachments } = this.state;

    let arrAttachment = [];
    listAttachments.forEach(att => {
      arrAttachment.push({
        URL: att.url ? att.url : "",
        FileName: att.fileName ? att.fileName : "",
        FileSize: att.fileSize ? att.fileSize : "",
        FileExt: att.fileExt ? att.fileExt : ""
      });
    });
    if (!_.isEmpty(dataOldAttachments)) {
      arrAttachment = dataOldAttachments.concat(arrAttachment);
    }

    return arrAttachment;
  };

  _removeCDN = () => {
    if (this.deletedFile && this.deletedFile.length > 0) {
      this.deletedFile.forEach(e => {
        const path = e.URL.split("=");
        if (path && path.length > 1) {
          const params = {
            path: path[1]
          };
          CDN.removeFile(params);
        }
      });
      this.deletedFile = [];
    }
  };

  componentDidMount = async () => {
    await this.loadPermission();
    if (!this.state.iPermission) return;
    const { location } = this.props;
    const DivisionID = Config.getDivisionID();
    const FormID = "W09F1501";
    const HostID = "";
    const Language = Config.language || "84";
    const TransID = _.get(location, "state.TransID", false);
    const mode = _.get(location, "state.mode", "add");

    this.loadApi("loadCboGenders", {}, true);
    this.loadApi("loadCboProvinces", {}, true);
    this.loadApi("loadCboDirectManagers", { DivisionID }, true);
    this.loadApi("loadCboPositions", {}, true);
    this.loadApi("getCboDepartments", { HostID, FormID, DivisionID, Language });
    if ((TransID && mode === "edit") || mode === "view") {
      this.loadApi("loadForm", { TransID, Language }, true);
      this.loadApi("getAttachmentsByTransID", { KeyID: TransID });
    }
  };

  componentWillUnmount = () => {
    this.setState({
      formLoading: false,
      uploading: false,
      iPermission: 0,
      errors: {},
      expandedFirst: true,
      expandedSecond: true,
      avatarAttach: null,
      formData: {
        EmployeeName: "",
        Sex: "",
        Telephone: "",
        DateJoined: null,
        NumIDCard: "",
        NumIDCardDate: null,
        NumIDCardPlace: "",
        IDCard: "",
        IDCardDate: null,
        IDCardPlace: "",
        DutyID: "",
        Project: null,
        ContactAddress: "",
        Note: "",
        UserPictureURL: "",
        DirectManagerID: "",
        DepartmentID: ""
      },
      dataOldAttachments: []
    });
    this.attachments = [];
    this.deletedFile = [];
    this.upload = null;
  };

  setStateErrorText(objValue, key) {
    const stateError = key ? key : "errors";
    this.setState({
      [stateError]: { ...(this.state[stateError] ? this.state[stateError] : {}), ...objValue }
    });
    return Object.keys(objValue).length !== 0;
  }

  deletedAvatar = URL => {
    if (!_.isEmpty(URL) && this.deletedFile.filter(e => e.URL === URL).length === 0)
      this.deletedFile = [...this.deletedFile, { URL }];
  };

  onUpoadAvatar = () => {
    const { getForm } = this.props;
    const UserPictureURL = _.get(getForm, "UserPictureURL", "");
    this.deletedAvatar(UserPictureURL);
    const arrFile = this.upload.files;
    const sizeLimit = 5000000;
    this.setState({ isChangePictureURL: true }, () => {
      for (let i = 0; i < arrFile.length; i++) {
        if (arrFile[i].size / 1024 > Number(sizeLimit)) {
          Config.popup.show("INFO", "Image is too large ." + sizeLimit + " KB");
        } else if (this.checkFileType(arrFile[i].name) === false) {
          Config.popup.show("INFO", "Wrong format.");
        } else {
          let file = arrFile[i];
          this.setState({
            avatarAttach: [file],
            formData: {
              ...this.state.formData,
              UserPictureURL: URL.createObjectURL(arrFile[i])
            }
          });
        }
      }
    });
  };

  onRemoveFile = () => {
    const { getForm } = this.props;
    const UserPictureURL = _.get(getForm, "UserPictureURL", "");
    this.deletedAvatar(UserPictureURL);
    this.setState({
      avatarAttach: null,
      formData: {
        ...this.state.formData,
        UserPictureURL: ""
      }
    });
  };

  onSaveAvatar = newAvatar => {
    if (newAvatar) {
      return new Promise((rl, rj) => {
        CDN.uploadFile(newAvatar, null, (error, res) => {
          if (error) {
            let message = error.message || "Error...";
            Config.popup.show("INFO", message);
            return rj(error);
          }
          if (res && res.paths.length > 0) {
            rl(res.paths);
          }
        });
      });
    }
  };

  checkFileType = filename => {
    const extension = filename.substr(filename.lastIndexOf(".")).toLowerCase();
    const allowedExtensions = [".jpg", ".png"];
    if (extension.length > 0) {
      if (allowedExtensions.indexOf(extension) === -1) {
        return false;
      }
    }
    return true;
  };

  checkValid = (name, val, dataFieldChecking = {}, rules = ["isRequired"]) => {
    let errString = "";
    const checking = {
      ID: !_.isEmpty(_.get(dataFieldChecking, "IDCard", null)),
      NumID: !_.isEmpty(_.get(dataFieldChecking, "NumIDCard", null))
    };
    switch (name) {
      case "EmployeeName":
      case "DateJoined":
        errString = val;
        break;
      case "NumIDCard":
      case "IDCard":
        errString = (checking.ID || checking.NumID) ? "notErr" : val;
        break;
      case "NumIDCardDate":
      case "NumIDCardPlace":
        if (checking.NumID) {
          errString = val
        } else if (checking.ID) {
          errString = "notErr"
        }
        break;
      case "IDCardDate":
      case "IDCardPlace":
        if (checking.ID) {
          errString = val
        } else if (checking.NumID) {
          errString = "notErr"
        }
        break;
      case "Sex":
        errString = val.toString();
        break;
      default:
        break;
    }
    return { name, rules, value: errString };
  };

  onSave = async () => {
    const { formData, avatarAttach } = this.state;
    const { location } = this.props;
    const mode = _.get(location, "state.mode", "add");
    const TransID = _.get(location, "state.TransID", false);
    const apiSave = mode === "add" ? mode : "edit";
    const {
      EmployeeName,
      Sex,
      Telephone,
      DateJoined,
      NumIDCard,
      NumIDCardDate,
      NumIDCardPlace,
      IDCard,
      IDCardDate,
      IDCardPlace,
      DutyID,
      Project,
      ContactAddress,
      Note,
      DirectManagerID,
      DepartmentID,
      UserPictureURL
    } = formData;

    const fEmployee = this.checkValid("EmployeeName", EmployeeName);
    const fSex = this.checkValid("Sex", Sex);
    const fDateJoined = this.checkValid("DateJoined", DateJoined);
    const fNumIDCard = this.checkValid("NumIDCard", NumIDCard, { NumIDCard, IDCard });
    const fIDCard = this.checkValid("IDCard", IDCard, { NumIDCard, IDCard });
    const fNumIDCardDate = this.checkValid("NumIDCardDate", NumIDCardDate, { NumIDCard, IDCard });
    const fNumIDCardPlace = this.checkValid("NumIDCardPlace", NumIDCardPlace, { NumIDCard, IDCard });
    const fIDCardDate = this.checkValid("IDCardDate", IDCardDate, { NumIDCard, IDCard });
    const fIDCardPlace = this.checkValid("IDCardPlace", IDCardPlace, { NumIDCard, IDCard });

    const validateForm = MForm.formValidation([
      fEmployee,
      fSex,
      fDateJoined,
      fNumIDCard,
      fIDCard,
      fNumIDCardDate,
      fNumIDCardPlace,
      fIDCardDate,
      fIDCardPlace
    ]);
    if (Object.keys(validateForm).length > 0) {
      this.setStateErrorText(validateForm);
      return false;
    } else {
      const arrAttachments = this._getAttachments();
      let newPic = !_.isEmpty(avatarAttach) && (await this.onSaveAvatar(avatarAttach));
      const DivisionID = Config.getDivisionID();
      let params = {
        DivisionID,
        EmployeeName,
        Sex,
        Telephone,
        DateJoined,
        NumIDCard,
        NumIDCardDate,
        NumIDCardPlace,
        IDCard,
        IDCardDate,
        IDCardPlace,
        DutyID,
        ProjectID: Project && Project.ProjectID ? Project.ProjectID : "",
        ContactAddress,
        Note,
        DirectManagerID,
        DepartmentID,
        UserPictureURL: _.get(newPic, "[0]url", UserPictureURL),
        arrAttachment: JSON.stringify(arrAttachments)
      };
      if (params.UserPictureURL.indexOf("/file") !== -1) {
        params.UserPictureURL = params.UserPictureURL.slice(params.UserPictureURL.indexOf("/file"));
      }
      if (TransID && apiSave === "edit") params = { ...params, TransID };
      this.setState({ formLoading: true });
      this.props.w09f1501Actions[apiSave](params, (error, data) => {
        this.setState({ formLoading: false });
        if (error) {
          let message = Config.lang("DHR_Loi_chua_xac_dinh");
          switch (error.code) {
            case "RGE002":
              message = "TransID " + Config.lang("DHR_Bat_buoc");
              break;
            case "RGE003":
              message = "Add " + Config.lang("DHR_Luu_khong_thanh_cong");
              break;
            case "RGE004":
              message = "Edit " + Config.lang("DHR_Luu_khong_thanh_cong");
              break;
            case "F1501E001":
              message = "TransID fail " + Config.lang("DHR_Luu_khong_thanh_cong");
              break;
            case null:
              message = "keyId " + Config.lang("DHR_Khong_ton_tai");
              break;
            case "null":
              message = "URL " + Config.lang("DHR_Bat_buoc");
              break;
            default:
              message = Config.lang("DHR_Luu_khong_thanh_cong");
              break;
          }
          Config.popup.show("INFO", message);
          return false;
        }
        if (data) {
          this._removeCDN();
          this.onBack();
          Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
        }
      });
    }
  };

  onChange = (e, nameField) => {
    const value = _.get(e, "value", _.get(e, "target.value", ""));
    this.setState({
      formData: {
        ...this.state.formData,
        [nameField]: value
      }
    });
  };

  onAttachment = () => {
    if (this.attRef) {
      this.attRef.onAttachment();
    }
  };

  onChangeAttachments = e => {
    this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
    if (e.deletedFiles && e.deletedFiles.length > 0) {
      this.deletedFile = [...e.deletedFiles];
      const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
      this.setState(prevState => ({
        dataOldAttachments: prevState.dataOldAttachments.filter(att => {
          return _arrRemove.indexOf(att.AttachmentID) < 0;
        })
      }));
    }
  };

  onUploading = value => {
    this.setState({ uploading: value });
  };

  onBack = () => {
    browserHistory.push(Config.getRootPath() + "W09F1500");
  };

  render() {
    const {
      formData,
      iPermission,
      dataOldAttachments,
      errors,
      expandedFirst,
      expandedSecond,
      formLoading
    } = this.state;
    // if (iPermission <= 0) return null;
    const { classes, location } = this.props;
    const mode = _.get(location, "state.mode", "add");
    const isView = mode === "view" && true;
    return (
      <>
        <ActionToolbar alignment="flex-end" title={Config.lang("DHR_Thong_tin_tang_nhan_vien")} onBack={this.onBack}>
          <ButtonGeneral
            name={Config.lang("DHR_Luu")}
            typeButton={"save"}
            style={{ textTransform: "uppercase" }}
            size={"large"}
            onClick={this.onSave}
            disabled={iPermission <= 1 || formLoading || isView}
          />
        </ActionToolbar>
        <Container maxWidth="lg" style={{ paddingTop: 20, marginLeft: 0 }}>
          <ExpansionPanel
            expanded={expandedFirst}
            style={{ boxShadow: "none" }}
            onChange={() => this.setState({ expandedFirst: !expandedFirst })}
          >
            <ExpansionPanelSummary
              className={classes.ExpansionPanelSummary}
              style={{ marginBottom: expandedFirst ? 0 : 35 }}
            >
              <i
                className={"fa display_row align-center " + (expandedFirst ? "fa-angle-down" : "fa-angle-right")}
                style={{ fontSize: 20, width: 20 }}
              />
              <Typography className={classes.heading}>{Config.lang("DHR_Thong_tin_ca_nhan")}</Typography>
              {!expandedFirst && (
                <span className={classes.txtNote}>
                  {Config.lang("DHR_Thong_tin_ca_nhan_DESC")}
                </span>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: 0 }}>
              <W09F1501EmployeeInfo
                upload={() => this.upload.click()}
                innerRef={ref => (this.upload = ref)}
                loading={formLoading}
                isView={isView}
                errorForm={errors}
                isEdit={mode === "edit"}
                formData={formData}
                onChange={(e, name) => this.onChange(e, name)}
                onRemoveFile={this.onRemoveFile}
                onUpoadFile={this.onUpoadAvatar}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expandedSecond}
            className={classes.noneBorder}
            style={{ boxShadow: "none", margin: "0 0 50px 0" }}
            onChange={() => this.setState({ expandedSecond: !expandedSecond })}
          >
            <ExpansionPanelSummary
              className={classes.ExpansionPanelSummary}
              style={{ marginBottom: expandedSecond ? 0 : 35 }}
            >
              <i
                className={"fa display_row align-center " + (expandedSecond ? "fa-angle-down" : "fa-angle-right")}
                style={{ fontSize: 20, width: 20 }}
              />
              <Typography className={classes.heading}>
                {Config.lang("DHR_To_chuc") + " - " + Config.lang("DHR_Tuyen_dung")}
              </Typography>
              {!expandedSecond && (
                <span className={classes.txtNote}>
                  {Config.lang("DHR_To_chuc_Tuyen_dung_DESC")}
                </span>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: 0 }}>
              <W09F1501OrgRec
                onAttachment={this.onAttachment}
                isView={isView}
                loading={formLoading}
                errorForm={errors}
                formData={formData}
                onChange={(e, name) => this.onChange(e, name)}
                onUploading={this.onUploading}
                onChangeAttachments={this.onChangeAttachments}
                dataOldAttachments={dataOldAttachments}
                onSave={this.onSave}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ButtonIcon name={"ArrowUp"} color={"primary"} viewType={"filled"} circular className={classes.buttonUp}
                      disabled={formLoading}
                      onClick={() => window.scrollTo(0, 0)}
          />
        </Container>
      </>
    );
  }
}
export default compose(
  connect(
    state => ({
      getForm: state.W09F1501.getForm,
      getCboGenders: state.W09F1501.getCboGenders,
      getCboProvinces: state.W09F1501.getCboProvinces,
      getCboPositions: state.W09F1501.getCboPositions,
      getCboDirectManagers: state.W09F1501.getCboDirectManagers
    }),
    dispatch => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w09f1501Actions: bindActionCreators(W09F1501Actions, dispatch)
    })
  ),
  withStyles(styles, { withTheme: true })
)(W09F1501);
